import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col, Placeholder } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { addNewPageLog, SEOTags } from "../contexts/API"
import '../contexts/i18n';
import { useTranslation } from "react-i18next";

export default function BrandCenterPage() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "100%";
  const bgImage = "https://pub-f9e4236cd9a1470d8b18e044f1fdbb19.r2.dev/SD6602_2038_compressed.jpeg"
  
  const { t, i18n } = useTranslation();
  const [currentCategory, setCurrentCategory] = useState('all')
  const [currentCategoryName, setCurrentCategoryName] = useState(i18n.language == 'en' ? 'All Brands' : '全部产品')
  const [products, setProducts] = useState([
      {
        title: "",
        titles: {
          en: '',
          zh: '',
        },
        photoURL: "",
        description: "",
        descriptions: {
          en: [],
          zh: [],
        },
        id: "",
        status: "active"
      }
  ])
  const [filteredProducts, setFilteredProducts] = useState([
    {
        title: "",
        titles: {
          en: '',
          zh: '',
        },
        photoURL: "",
        description: "",
        descriptions: {
          en: [],
          zh: [],
        },
        id: "",
        status: "active"
    }
])
  const [categories, setCategories] = useState([])
  
  useEffect(() => {
      addNewPageLog()

      if (window.location.pathname.endsWith('-zh')){
        i18n.changeLanguage('zh')
      }
      else{
        //en
        i18n.changeLanguage('en')
      }

      reloadProducts()
      reloadCategory()
  },[])

  const reloadProducts = () => {
    setFilteredProducts([])
    getDocs(query(collection(fs,"products"), where("homepage","==",true), orderBy('order','asc')))
      .then((snapshot) => {
          var formss = []
          snapshot.docs.forEach((doc) => {
              const id = doc.id
              var formData = doc.data()
              formData.id = id
              formss.push(formData)
              
          })
          setProducts(formss)
          setFilteredProducts(formss)
      })
    
  }

  function filterOutProducts(categoryItm) {
    if (categoryItm == "all"){
      setFilteredProducts(products)
    }
    else{
      const filteredProd = products.filter((itm) => {
        return itm.category == categoryItm
      })
      setFilteredProducts(filteredProd)
    }
  }

  const reloadCategory = () => {
    const q = query(collection(fs, "categories"), orderBy("order","asc"));
    getDocs(q).then((snapshot) => {
        var formss = []
        snapshot.docs.forEach((doc) => {
            const id = doc.id
            var formData = doc.data()
            formData.id = id
            formss.push(formData)
        })
        setCategories(formss)
    })
  }
 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: '#FFFFFF'
        }}
      >
        <SEOTags
        title={i18n.language == 'en' ? "Saide Quartz | Brand Center" : "赛德石英石 ｜ 商品中心"}
        description={i18n.language == 'en' ? "View list of brands Saide offered" : "赛德石英石，品质保证"}
        name={i18n.language == 'en' ? "Saide Quartz" : "赛德石英石"}
        type="article"
        image={bgImage} />

        <div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>
            <div 
            className="d-flex align-items-center justify-content-center"
            style={{
          width: window.innerWidth,
          height: window.innerHeight / 2,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundImage: `url(${bgImage})`
        }}>
            <h1 className="text-center" style={{color: 'black', fontSize: 45, fontWeight: 'bold'}}>{currentCategoryName}</h1>
        </div>

        <div style={{margin: "45px"}}>

        <Row>
                        <Col sm={2} style={{marginTop: '10px'}}>
                            <ListGroup className="border-0" style={{borderRadius: '0px'}}>
                                <ListGroup.Item className='border-0' style={{borderRadius: '0px'}} action onClick={()=>{
                                  setCurrentCategory("all")
                                  filterOutProducts("all")
                                  setCurrentCategoryName(i18n.language == 'en' ? "All Brands" : '全部产品')
                                  
                                }}>
                                    <div className={currentCategory == 'all' ? 'fw-bold' : 'fw-normal'}>{i18n.language == 'en' ? '• All Brands' : '• 全部产品'}</div>
                                </ListGroup.Item>
                                {
                                  categories.map((cateItm) => {
                                    let itmName = ""
                                    let itmId = ""
                                    if (cateItm.id != null) {
                                      itmId = cateItm.id
                                    }
                                    if (cateItm.titles != null){
                                      if (i18n.language == 'en'){
                                        itmName = cateItm.titles.en
                                      }
                                      else{
                                        itmName = cateItm.titles.zh
                                      }
                                    }
                                    return <ListGroup.Item className='border-0' style={{backgroundColor: currentCategory == itmId ? '#EEEEEEE' : '#FFFFFF'}} action onClick={()=>{
                                      if (itmId != ""){
                                        
                                        setCurrentCategory(itmId)
                                        filterOutProducts(itmId)
                                        setCurrentCategoryName(itmName)

                                      }
                                    }}>
                                    <div className={currentCategory == itmId ? 'fw-bold' : 'fw-normal'}>• {itmName}</div>
                                </ListGroup.Item>
                                  })
                                }
                                
                            </ListGroup>
                        </Col>
                        <Col sm={10} >


                        <Row md={2}>
          {
            filteredProducts.map((pItm) => {
              return <Col>
              <Card className="border-0" style={{ marginTop: "20px", borderRadius: '0px'}} hidden={isMobile}>
                <Card.Body>
                  <Card.Img variant="top" style={{objectFit: 'cover', height: '250px'}} src={pItm.photoURL}/>
                  <Card.Title style={{marginTop: "10px", marginBottom: "10px", fontSize: 17, fontWeight: "normal"}}>{i18n.language == 'en' ? pItm.titles.en : pItm.titles.zh}</Card.Title>
                  <Card.Link href={i18n.language == 'en' ? "/product/"+pItm.id : "/product-zh/"+pItm.id} style={{marginTop: "5px", marginBottom: "5px", fontSize: 15, fontWeight: "normal", color: 'black'}}>{i18n.language == 'en' ? 'View details' : '查看详情'}</Card.Link>
                </Card.Body>
              </Card>
              </Col>
            })
          }
        </Row>
                        </Col>
        </Row>

        {
            filteredProducts.map((pItm) => {
              return <Card className="border-0" style={{ marginTop: "20px", borderRadius: '0px'}} hidden={!isMobile}>
                <Card.Body>
                  <Card.Img variant="top" style={{objectFit: 'cover', height: '200px'}} src={pItm.photoURL}/>
                  <Card.Title style={{marginTop: "10px", marginBottom: "10px", fontSize: 17, fontWeight: "normal"}}>{i18n.language == 'en' ? pItm.titles.en : pItm.titles.zh}</Card.Title>
                  <Card.Link href={i18n.language == 'en' ? "/product/"+pItm.id : "/product-zh/"+pItm.id} style={{marginTop: "5px", marginBottom: "5px", fontSize: 15, fontWeight: "normal", color: 'black'}}>{i18n.language == 'en' ? 'View details' : '查看详情'}</Card.Link>
                </Card.Body>
              </Card>
            })
          }

        

        </div>

        

        </div>
        
        
      </Container>
      
    </>
  );
}
