import React, { useContext, useState, useEffect } from "react";
import { auth, fs } from "../firebase";
import { isMobile, isIOS, isAndroid, isBrowser, isDesktop} from "react-device-detect"
import { Helmet } from 'react-helmet';
import { collection, addDoc, getDoc, updateDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";


export async function addNewPageLog(){
    const ua = window.navigator.userAgent.toLowerCase()
    const url = window.location.href
    const url_origin = window.location.origin
    const path_name = window.location.pathname
    var userId = ""
    var deviceType = ""
    var sourceType = ""
    var sourceFrom = ""

    if (ua.includes("micromessenger")){
        sourceFrom = "wechat"
    }
    else if (ua.includes("linkedin")){
        sourceFrom = "linkedin"
    }
    else if (ua.includes("fb")){
        sourceFrom = "facebook"
    }
    else if (ua.includes("weibo")){
        sourceFrom = "weibo"
    }
    else if (ua.includes("twitter")){
        sourceFrom = "twitter"
    }
    else{
        sourceFrom = "browser"
    }

    
    deviceType = isIOS ? "ios" : isAndroid ? "android" : "desktop"
    sourceType = isMobile ? "mobile" : "desktop"

    if (auth.currentUser != null){
        userId = auth.currentUser.uid
    }
    var ip_address = ""
    try {
        const ip_response = await fetch('https://api.ipify.org');
        const data_ip = await ip_response.text();
        ip_address = data_ip
    }
    catch (error) {
        console.error('Something wrong')
    }
    
    addDoc(collection(fs,"views"), {
        url: url,
        urlOrigin: url_origin,
        urlPathname: path_name,
        deviceType: deviceType,
        sourceType: sourceType,
        sourceFrom: sourceFrom,
        userAgent: ua,
        uid: userId,
        ipAddress: ip_address,
        timestamp: Timestamp.fromDate(new Date())
    })
}


export function SEOTags({title, description, name, type, image}) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type} />
<meta property="og:title" content={title} />
<meta property="og:description" content={description} />
<meta property="og:image" content={image} />
{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content={name} />
<meta name="twitter:card" content={type} />
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />
<meta name="twitter:image" content={image} />
{ /* End Twitter tags */ }
</Helmet>
)
}