import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col, Tab, Modal, FloatingLabel, Badge, Stack, DropdownButton, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../../contexts/AuthContext";
import { fs, auth, storage } from "../../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import { signOut, updateCurrentUser, sendEmailVerification } from "firebase/auth"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { PieChart } from 'react-minimal-pie-chart';

import { addNewPageLog } from "../../contexts/API"

export default function DashboardPage() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [productMessage, setProductMessage] = useState("")
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "100%";

  const [forms, setForms] = useState([])
  const [views, setViews] = useState([])
  const [todayViews, setTodayViews] = useState([])
  
  const [products, setProducts] = useState([])
  const [posts, setPosts] = useState([])
  const [category, setCategory] = useState([])

  const prod_eng = `High quality raw stone\nHigh quality quartz jade certified by professional institutions.\nGreen and environmentally friendly\nThe surface of the material is dense, without hair or fine pores, with low water absorption rate, and the oil fume is not easy to penetrate. Bacteria and molds cannot survive.\nexcellent weatherability\nThe board has strong frost resistance and heat resistance, with a surface hardness of 7 Mohs, making it durable and long-lasting.\nGem appearance\nThe texture is moist and shiny, with natural natural stone colors. The board displays rich colors, distinguishing the monotonous colors of quartz stone.
  `
  const prod_zh = `高品质原石\n经专业机构认证的高品质石英玉石。\n绿色环保\n材质表面致密，无毛发或细孔，吸水率低，油烟不易渗透。细菌和霉菌无法生存。\n优异的耐候性\n该板具有较强的抗冻性和耐热性，表面硬度达到7莫氏，经久耐用。\n宝石外观\n质地湿润有光泽，具有天然的天然石色。板材呈现出丰富的色彩，区别石英石单调的色彩。
  `

  const [showAddProduct, setShowAddProduct] = useState(false)
  const [productImageUrl, setProductImageUrl] = useState("")
  const [productNew, setProductNew] = useState(true)
  const [productNewId, setProductNewId] = useState("")
  const [productItm, setProductItm] = useState({
      photoURL: "",
      title: "",
      description: "",
      titles: {
          en: "",
          zh: "",
      },
      descriptions: {
          en: [],
          zh: []
      },
      category: "",
      id: "",
      visible: true,
      homepage: true,
      filename: "",
      keywords: [],
      order: -1,
  })

  const [viewDetails, setViewDetails] = useState({
      visitor: 0,
      admin: 0,
      visitorToday: 0,
      adminToday: 0,
      mobile: 0,
      desktop: 0,
      android: 0,
      ios: 0,
      web: 0,
      pageDetails: {
      },
      topPages: [],
      sourceDetails: {
        home: 0
      },
      sourceFromDetails: {

      },
      pages: [],
      sources: [],
      sourceFrom: [],
  })

  const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  async function loadPageAnalysis() {
      const adminV = views.filter((vItm) => { return  vItm.uid != ""}).length
      const adminTodayV = todayViews.filter((vItm) => { return vItm.uid != ""}).length
      const visitorV = views.length - adminV
      const visitorTodayV = todayViews.length - adminTodayV
      
      var vDetails = viewDetails
      vDetails.admin = adminV
      vDetails.adminToday = adminTodayV
      vDetails.visitor = visitorV
      vDetails.visitorToday = visitorTodayV

      
      views.forEach((vItm) => {
          if (vItm.sourceType != null && vItm.sourceType != ""){
              if (vItm.sourceType == "mobile"){ vDetails.mobile += 1 }
              else{ vDetails.desktop += 1}
          }
          if (vItm.deviceType != null && vItm.deviceType != ""){
              if (vItm.deviceType == "ios"){ vDetails.ios += 1}
              else if (vItm.deviceType == "android"){ vDetails.android += 1}
              else{ vDetails.web += 1 }
          }
      } )

      

      setViewDetails(vDetails)
  }

  const handleImageSelected = (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    if (file != null){
        const filename = file.name;
        uploadImageFile(file, filename);
    }
  }

  async function uploadImageFile(file, fileName){
    const storageRef = ref(storage, `saide/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef,file)
    uploadTask.on(
    "state_changed",
    (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
    }, (error) => {
        //unsuccessful

    }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            const imageString = downloadUrl
            setProductImageUrl(imageString)

            var itm = productItm
            itm.photoURL = imageString
            itm.filename = fileName
            setProductItm(itm)
        })
    })
  }

  async function handleProductSubmit(e) {
      e.preventDefault()
      const imageURL = e.target[1].value
      const zhTitle = e.target[2].value
      const enTitle = e.target[3].value
      const zhDesc = e.target[4].value
      const enDesc = e.target[5].value

      var newProduct = productItm

      alert(zhTitle + "\n" + enTitle)

      if (enTitle != "" && enDesc != "" && productImageUrl != ""){
          //
          newProduct.title = enTitle
          newProduct.titles = {en: enTitle, zh: zhTitle}
          newProduct.description = enDesc

          var enDescs = []
          var zhDescs = []
          if (enDesc.includes("\n")){
              enDescs = enDesc.split("\n").filter((str) => { return str != ""})
          }
          else{
              enDescs = [enDesc]
          }
          if (zhDesc.includes("\n")){
             zhDescs = zhDesc.split("\n").filter((str) => { return str != ""})
          }
          else{
              zhDescs = [zhDesc]
          }
          
          newProduct.descriptions = {en: enDescs, zh: zhDescs}
          newProduct.order = products.length

          setProductItm(newProduct)
          setProductMessage("生成产品预览")

      }
  }

  async function addNewProduct() {
      if (productItm.title != "" && productItm.description != "" && productItm.photoURL != "" ){
          if (productNew){
              if (productNewId == ""){
                addDoc(collection(fs, "products"), productItm).then((addedItm) => {
                    const productId = addedItm.id
                    updateDoc(doc(fs, "products", productId), {id: productId, addedBy: auth.currentUser.uid, timestamp: Timestamp.fromDate(new Date())}).then((updateRef)=>{
                      setShowAddProduct(false)
                      reloadProducts()
                    })
                })
              }
              else{
                  setDoc(doc(fs, "products", productNewId), productItm).then((aa) => {
                    updateDoc(doc(fs, "products", productNewId), {id: productNewId, addedBy: auth.currentUser.uid, timestamp: Timestamp.fromDate(new Date())}).then((updateRef)=>{
                        setShowAddProduct(false)
                        reloadProducts()
                      })
                  })
              }
          }
          else{
              if (productItm.id != ""){
                  updateDoc(doc(fs,"products",productItm.id), productItm).then((aa)=>{
                      setShowAddProduct(false)
                      setProductNew(true)
                      reloadProducts()
                  })
              }
          }

          setProductNewId("")
          
      }
      else{
          alert("无法添加产品")
      }
  }

  const productForm = () => {
      return <div>
          <Modal show={showAddProduct} onHide={()=>{
          setShowAddProduct(false)
          setProductNewId("")
      }}>
          <Modal.Header closeButton><Card.Title >{productNew ? "添加新产品" : "修改产品"}</Card.Title></Modal.Header>
          <Modal.Body>
          <Card style={{marginTop: '25px', marginBottom: '25px'}}>
                      <Card.Body>
                        <Card.Text style={{margin: '10px'}}>产品展示</Card.Text>
                          <Card.Img variant="top" src={productItm.photoURL}/>
                          <Card.Title style={{margin: '10px'}}>{productItm.title}</Card.Title>
                          <Card.Text style={{margin: '10px'}}>{productItm.description}</Card.Text>
                      </Card.Body>
                  </Card>
              <Form onSubmit={(e)=>{
                  handleProductSubmit(e)
              }}>
                  
                  <Form.Group style={{marginTop: '15px'}}>
                    <Form.Label>上传产品图片</Form.Label><br/>
                    <input
                    type="file"
                    name="coverImage"
                    title="选择照片"
                    onChange={(e)=>{
                        handleImageSelected(e)
                    }}/>
                    <Form.Control type="text" value={productItm.photoURL} style={{marginTop: '15px'}} onChange={(e) => {
                        const imgUrl = e.target.value
                        if (imgUrl != ""){
                            setProductImageUrl(imgUrl)
                            var itm = productItm
                            itm.photoURL = imgUrl
                            itm.filename = ""
                            setProductItm(itm)
                        }
                        
                    }}/>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label="中文产品名称">
                        <Form.Control type="text" defaultValue={productItm.titles.zh}/>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label="英文产品名称(主要)">
                        <Form.Control type="text" defaultValue={productItm.titles.en} onChange={(e) => {
                            const targetName = e.target.value
                            if (productNew){
                                const targetNewId = targetName.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'').replace(/\s+/g,"-").trim()
                                if (targetNewId.length < 100){
                                    setProductNewId(targetNewId)
                                }
                            }
                            else{
                                
                            }
                            
                        }}/>
                    </FloatingLabel>
                    <Form.Label style={{marginTop: '10px'}}>{productNewId != "" ? "已自动生成ID: " + productNewId : ""}</Form.Label>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label="中文产品介绍">
                        <Form.Control as="textarea" rows={5} defaultValue={prod_zh}/>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label="英文产品介绍(主要)">
                        <Form.Control as="textarea" rows={5} defaultValue={prod_eng}/>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <Form.Label>选择类别</Form.Label>
                    <DropdownButton
                    variant="outline-dark"
                    title={`选择类别 - ${productItm.category}`}
                    onSelect={(e)=>{
                        let productIm = productItm
                        productIm.category = e
                        setProductItm(productIm)
                        setSuccess('updated category - ' + e)
                    }}>
                        {
                            category.map((itm) => {
                                const cate_id = itm.id
                                return <Dropdown.Item eventKey={cate_id}>{cate_id}</Dropdown.Item>
                            })
                        }
                    </DropdownButton>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <Button className="w-100" type="submit" variant="dark" style={{fontSize: 17, fontWeight: 'bold'}} hidden={!productNew}>生成预览</Button>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <Button className="w-100" variant="success" style={{fontSize: 17, fontWeight: 'bold'}} onClick={()=>{
                        addNewProduct()
                    }}>{productNew ? "添加新产品" : "修改产品"}</Button>
                  </Form.Group>
              </Form>
          </Modal.Body>
      </Modal>
      </div>
  }
  
  
  useEffect(() => {
    addNewPageLog()
    reloadViews()
    reloadForms()
    reloadProducts()
    reloadPosts()
    reloadCategory()
  },[])

  const reloadViews = () => {
    getDocs(query(collection(fs,"views"), orderBy("timestamp","desc"))).then((snapshot) => {
        var formss = []
        var todayVi = []
        snapshot.docs.forEach((doc) => {
            const id = doc.id;
            var formData = doc.data()
            formData.id = id
            formss.push(formData)
            
            var timestampp = Timestamp.fromDate(new Date())
            if (formData.timestamp != null){
                timestampp = formData.timestamp
                if (isToday(timestampp.toDate())){
                    todayVi.push(formData)
                }
            }
        })
        setViews(formss)
        setTodayViews(todayVi)
        
    })
  }

  const reloadProducts = () => {
    getDocs(query(collection(fs,"products"))).then((snapshot) => {
        var formss = []
        snapshot.docs.forEach((doc) => {
            const id = doc.id
            var formData = doc.data()
            formData.id = id
            formss.push(formData)
        })
        setProducts(formss)
    })
  }
  const reloadPosts = () => {
    getDocs(query(collection(fs,"posts"))).then((snapshot) => {
        var formss = []
        snapshot.docs.forEach((doc) => {
            const id = doc.id
            var formData = doc.data()
            formData.id = id
            formss.push(formData)
        })
        setPosts(formss)
    })
  }
  const reloadForms = () => {
    const q = query(collection(fs, "forms"), orderBy("timestamp","desc"));
    getDocs(q).then((snapshot) => {
        var formss = []
        snapshot.docs.forEach((doc) => {
            const id = doc.id
            var formData = doc.data()
            formData.id = id
            formss.push(formData)
        })
        setForms(formss)
    })
  }
 
  const reloadCategory = () => {
    const q = query(collection(fs, "categories"), orderBy("order","asc"));
    getDocs(q).then((snapshot) => {
        var formss = []
        snapshot.docs.forEach((doc) => {
            const id = doc.id
            var formData = doc.data()
            formData.id = id
            formss.push(formData)
        })
        setCategory(formss)
    })
  }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >

          {
              productForm()
          }

<div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>
            <div 
            className="d-flex align-items-center justify-content-center"
            style={{
          width: window.innerWidth,
          height: window.innerHeight / 3,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b)"
        }}>
            <h1 className="text-center" style={{color: 'black', fontSize: 30, fontWeight: 'bold'}}>赛德网页管理系统</h1>
        </div>
        {success && <Alert variant="success">{success}</Alert>}
        <div className="d-flex justify-content-between align-items-start" style={{margin: isMobile ? "20px" : "100px"}}>
        <Card.Title
                          style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          管理页面
                        </Card.Title>
                        <div style={{display: 'flex'}}>
                            <Button variant={auth.currentUser.emailVerified ? "dark-outline" : "outline-dark"} style={{marginRight: '15px'}} onClick={()=>{
                                try {
                                    if (auth.currentUser != null){
                                        if (!auth.currentUser.emailVerified){
                                            sendEmailVerification(auth.currentUser).then((itm) => {
                                                setSuccess("验证邮件已发送")
                                            })
                                        }
                                        else{
                                            //email verified
                                            setSuccess("账户已经验证")
                                        }
                                    }
                                }
                                catch(e) {

                                }
                            }}>{auth.currentUser.emailVerified ? "已验证账户" : "请验证邮箱"}</Button>
                            <Button variant="outline-danger" onClick={()=>{
                                try {
                                    signOut(auth)
                                    window.location.href = "/"
                                }
                                catch(e) {

                                }
                            }}>退出</Button>

                        </div>
                </div>

                <div style={{marginLeft: isMobile ? "20px" : "100px", marginRight: isMobile ? "20px" : "100px", marginBottom: "200px"}}>
                    <Tab.Container id="tab_management" defaultActiveKey={'#forms'}>
                    <Row>
                        <Col sm={2} style={{marginTop: '20px'}}>
                            <ListGroup>
                                <ListGroup.Item action href={'#basic-info'}>
                                    <div className="fw-bold">基本信息</div>
                                </ListGroup.Item>
                                <ListGroup.Item action href={'#forms'}>
                                    <div className="fw-bold">联络单</div>
                                </ListGroup.Item>
                                <ListGroup.Item action href={'#products'}>
                                    <div className="fw-bold">产品</div>
                                </ListGroup.Item>
                                <ListGroup.Item action href={'#views-analysis'} onClick={()=>{
                                    loadPageAnalysis()
                                }}>
                                    <div className="fw-bold">网页访问分析</div>
                                </ListGroup.Item>
                                <ListGroup.Item action href={'#web-pages'}>
                                    <div className="fw-bold">网页页面</div>
                                </ListGroup.Item>
                                <ListGroup.Item action href={'#navigation'}>
                                    <div className="fw-bold">导航栏</div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={10} style={{marginTop: '20px'}}>
                        <Tab.Content>
                            <Tab.Pane eventKey="#basic-info">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>基本信息</Card.Text>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="#forms">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>联络单 ({forms.length})</Card.Text>
                              </div>
                              <ListGroup style={{marginTop: '25px'}}>
                                  {
                                      forms.map((formItm) => {
                                          var formId = formItm.id
                                          var fn = ""
                                          var ln = ""
                                          var email = ""
                                          var message = ""
                                          var timestamp = Timestamp.fromDate(new Date())
                                          if (formItm.firstName != null){ fn = formItm.firstName }
                                          if (formItm.lastName != null){ ln = formItm.lastName }
                                          if (formItm.email != null){ email = formItm.email }
                                          if (formItm.message != null){ message = formItm.message }
                                          if (formItm.timestamp != null){ timestamp = formItm.timestamp }

                                          return <ListGroup.Item>
                                              <Card.Title>{ fn + " " + ln}</Card.Title>
                                              <Card.Link href={"MAILTO:"+email}>{email}</Card.Link>
                                              <Card.Text style={{marginTop: '20px'}}>{message}</Card.Text>
                                              <Card.Text style={{marginTop: '20px'}}>{timestamp.toDate().toLocaleString('zh')}</Card.Text>
                                              <Stack direction="horizontal" style={{marginTop: '25px'}}>
                                              <Card.Link style={{color: 'black', marginRight: '5px'}} onClick={()=>{
                                                  let dConfirm = window.confirm("确定想删除吗？")
                                                  if (dConfirm && formId != null){
                                                    if (formId != ""){
                                                        deleteDoc(doc(fs,"forms",formId)).then((ref)=>{reloadForms()})
                                                    }
                                                  }
                                                  else{

                                                  }
                                              }}>删除</Card.Link>
                                              <Card.Link style={{color: 'black', marginRight: '5px'}} onClick={()=>{
                                                  window.navigator.clipboard.writeText(email)
                                                  window.alert("邮箱已经复制")
                                              }}>复制邮箱</Card.Link>
                                              <a href={"mailto:"+email} style={{color: 'black'}}>回复邮件</a>                                             
                                              </Stack>
                                              
                                          </ListGroup.Item>
                                      })
                                  }
                              </ListGroup>
                            </Tab.Pane>


                            <Tab.Pane eventKey="#products">
                              <div className="d-flex align-items-start justify-content-between">
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>产品 ({products.length})</Card.Text>
                                <Button variant="success" onClick={()=>{
                                    setShowAddProduct(true)
                                    setProductNew(true)
                                    setProductItm({
                                        photoURL: "",
                                        title: "",
                                    description: "",
                                    titles: {
                                        en: "",
                                        zh: "",
                                    },
                                    descriptions: {
                                        en: [],
                                        zh: []
                                    },
                                    category: "",
                                    id: "",
                                    visible: true,
                                    homepage: true,
                                    filename: "",
                                    keywords: [],
                                    order: -1,
                                    })
                                }}>添加新产品</Button>
                              </div>
                              <Row md={3}>
                                  {
                                      products.map((pItm) => {
                                          
                                          return <Col>
                                          <Card>
                                              <Card.Img variant="top" src={pItm.photoURL}/>
                                              <Card.Title className="text-center" style={{margin: '10px'}}>{pItm.title}</Card.Title>
                                              <Card.Text style={{margin: '10px'}}>{pItm.description.substring(0,20)}</Card.Text>
                                              <div className="d-flex align-items-center justify-content-center" style={{margin: '0px'}}>
                                              <Badge bg="success" pill>{pItm.homepage ? "首页展示中" : "未在首页展示"}</Badge>
                                              </div>
                                              <Stack direction="horizontal" style={{margin: '10px'}}>
                                                  <Card.Link style={{color: 'black'}} onClick={()=>{
                                                      setProductItm(pItm)
                                                      setProductNew(false)
                                                      setShowAddProduct(true)
                                                  }}>编辑</Card.Link>
                                                  <Card.Link style={{color: "red"}} onClick={()=>{
                                                      let dConfirm = window.confirm("您想要删除该产品吗？")
                                                      if (dConfirm){
                                                        if (pItm.id != ""){
                                                            deleteDoc(doc(fs,"products",pItm.id)).then((ref)=>{reloadProducts()})
                                                          }
                                                      }
                                                      else{

                                                      }
                                                      
                                                  }}>删除</Card.Link>
                                                  <Card.Link style={{color: 'black'}} onClick={()=>{
                                                      if (pItm.homepage){
                                                          updateDoc(doc(fs, "products", pItm.id), {homepage: false}).then(()=>{reloadProducts()})
                                                      }
                                                      else{
                                                        updateDoc(doc(fs, "products", pItm.id), {homepage: true}).then(()=>{reloadProducts()})
                                                      }
                                                  }}>{pItm.homepage ? "不在首页展示" : "在首页展示"}</Card.Link>
                                              </Stack>
                                              
                                          </Card>
                                          </Col>
                                      })
                                  }
                              </Row>
                            </Tab.Pane>


                            <Tab.Pane eventKey="#views-analysis">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>网页访问分析 (历史 {views.length} ｜ 今日 {todayViews.length})</Card.Text>
                              </div>
                              <Row style={{marginTop: '25px'}}>
                                  <Col sm={4}>
                                  <Card.Title style={{marginBottom: '15px'}}>访问数据</Card.Title>
                                  <ListGroup>
                                  <ListGroup.Item>
                                      历史访客访问：{viewDetails.visitor}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                      今日访客访问：{viewDetails.visitorToday}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                      历史管理员访问：{viewDetails.admin}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                      今日管理员访问：{viewDetails.adminToday}
                                  </ListGroup.Item>
                                    </ListGroup>
                                    
                                  </Col>
                                  <Col sm={4}>
                                  <Card.Title style={{marginBottom: '15px'}}>访问来源</Card.Title>
                                  <ListGroup>
                                  <ListGroup.Item>
                                      来自苹果：{viewDetails.ios}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                      来自安卓：{viewDetails.android}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                      来自网页：{viewDetails.web}
                                  </ListGroup.Item>
                                  
                                    </ListGroup>
                                  </Col>
                                  <Col sm={4}>
                                  <Card.Title style={{marginBottom: '15px'}}>设备来源</Card.Title>
                                  <ListGroup>
                                  <ListGroup.Item>
                                      来自手机端：{viewDetails.mobile}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                      来自电脑端：{viewDetails.desktop}
                                  </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                              </Row>

                              <div>
                                <Row md={3} style={{marginTop: '15px'}}>
                                    <Col>
                                    <div>
                                        <Card.Text style={{fontSize: '18px', fontWeight: 'bold', color: 'black'}}>网页访客分析</Card.Text>
                                    </div>
                                    <PieChart
                                    style={{width: '75%', height: '75%'}}
                                    label={({ dataEntry }) => `${dataEntry.title} ${Math.round(dataEntry.percentage)} %`}
                                    labelStyle={{fontSize: '5px', fontWeight: 'bold'}}
                                data={[
                                    { title: '历史访客', value: viewDetails.visitor, color: '#E38627' },
                                    { title: '今日访客', value: viewDetails.visitorToday, color: '#C13C37' },
                                ]}
                                />
                                    </Col>

                                    <Col>
                                    <div>
                                        <Card.Text style={{fontSize: '18px', fontWeight: 'bold', color: 'black'}}>访问来源分析</Card.Text>
                                    </div>
                                    <PieChart
                                    style={{width: '75%', height: '75%'}}
                                    label={({ dataEntry }) => `${dataEntry.title} ${Math.round(dataEntry.percentage)} %`}
                                    labelStyle={{fontSize: '5px', fontWeight: 'bold'}}
                                data={[
                                    { title: '网页访客', value: viewDetails.web, color: '#E38627' },
                                    { title: '苹果访客', value: viewDetails.ios, color: '#C13C37' },
                                    { title: '安卓访客', value: viewDetails.android, color: '#6A2135' },
                                ]}
                                />
                                    </Col>

                                    <Col>
                                    <div>
                                        <Card.Text style={{fontSize: '18px', fontWeight: 'bold', color: 'black'}}>设备来源分析</Card.Text>
                                    </div>
                                    <PieChart
                                    style={{width: '75%', height: '75%'}}
                                    label={({ dataEntry }) => `${dataEntry.title} ${Math.round(dataEntry.percentage)} %`}
                                    labelStyle={{fontSize: '5px', fontWeight: 'bold'}}
                                data={[
                                    { title: '手机端', value: viewDetails.mobile, color: '#E38627' },
                                    { title: '电脑端', value: viewDetails.desktop, color: '#C13C37' },
                                ]}
                                />
                                    </Col>
                                </Row>
                              

                              </div>

                              <ListGroup>
                              <ListGroup.Item>
                                <Row md={5}>
                                                  <Col>访问时间</Col>
                                                  <Col>来源</Col>
                                                  <Col>用户ID</Col>
                                                  <Col>访问页面</Col>
                                </Row>
                                          </ListGroup.Item>
                                  {
                                      views.map((viewItm) => {
                                          let viewUId = ""
                                          if (viewItm.uid != null){
                                              viewUId = viewItm.uid
                                          }
                                          if (viewUId != ""){
                                              viewUId = "管理员"
                                          }
                                          else{
                                            viewUId = "访客"
                                          }
                                          return <ListGroup.Item>
                                              <Row md={5}>
                                                  <Col>{viewItm.timestamp.toDate().toLocaleString('en')}</Col>
                                                  <Col>{viewItm.deviceType}</Col>
                                                  <Col>{viewUId}</Col>
                                                  <Col>{viewItm.urlPathname}</Col>
                                              </Row>
                                          </ListGroup.Item>
                                      })
                                  }
                              </ListGroup>
                              

                            </Tab.Pane>
                            <Tab.Pane eventKey="#web-pages">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>网页页面</Card.Text>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#navigation">
                              <div>
                                <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>导航栏</Card.Text>
                              </div>
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                    </Tab.Container>
                </div>


        </div>
        
      </Container>
      
    </>
  );
}
