import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth"
import { addNewPageLog } from "../contexts/API"

export default function Login() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "80%";
  const { login } = useAuth()
  
  async function submitForm(e) {
      e.preventDefault()
      const email = e.target[0].value
      const password = e.target[1].value
      try {
        await signInWithEmailAndPassword(auth,email,password)
        window.location.href = "/"
      }
      catch(err) {
          setError("Login failed - something is wrong")
      }
      
      
  }
  
  useEffect(() => {
      addNewPageLog()
  },[])
 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >

        <div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>
            <div 
            className="d-flex align-items-center justify-content-center"
            style={{
          width: window.innerWidth,
          height: window.innerHeight / 2,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b)"
        }}>
            <h1 className="text-center" style={{color: 'black', fontSize: isMobile ? 35 : 45, fontWeight: 'bold'}}>Dashboard Management</h1>
        </div>

        <div className="d-flex align-items-center justify-content-center">

        <Card style={{  marginTop: '45px', marginBottom: '45px'}}>
            <Card.Body>
            <h1 className="text-center" style={{fontSize: isMobile ? 30 : 40, fontWeight: 'bold'}}>Login</h1>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form style={{width: 300, marginRight: '15px', marginLeft: '15px'}} onSubmit={(e)=>{submitForm(e)}}>
                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label="Email Address *">
                        <Form.Control type="email" required/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label="Password *">
                        <Form.Control type="password" required/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                      <Button className="w-100" variant="dark" style={{height: '45px', fontWeight: 'bold'}} type="submit">Login</Button>
                      <div className="d-flex align-items-center justify-content-center" style={{marginTop: '25px'}}>
                        <Card.Link href="/forget-password" style={{color: 'black'}}>Forgot password?</Card.Link>
                      </div>
                  </Form.Group>

                  <Form.Group>
                      <Card.Link></Card.Link>
                  </Form.Group>
              </Form>

              
            </Card.Body>
          </Card>
          

        </div>

        
        </div>

        
      </Container>
     
    </>
  );
}
