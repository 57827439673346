import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { addNewPageLog, SEOTags } from "../contexts/API"

export default function PageNotFound() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "100%";
  
  
  useEffect(() => {
      addNewPageLog()
  },[])
 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >
        <SEOTags
        title="Page not found"
        description="Page not found"
        name="Page is not found"
        type="article"
        image="" />

<div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>
            <div 
            className="d-flex align-items-center justify-content-center"
            style={{
          width: window.innerWidth,
          height: window.innerHeight / 2,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b)"
        }}>
            <h1 className="text-center" style={{color: 'black', fontSize: 45, fontWeight: 'bold'}}>404 Page Not Found</h1>
        </div>


        </div>
        
      </Container>
      
    </>
  );
}
