import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { addNewPageLog, SEOTags } from "../contexts/API"
import '../contexts/i18n';
import { useTranslation } from "react-i18next";

export default function AboutPage() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "100%";
  const bgImage = "https://pub-f9e4236cd9a1470d8b18e044f1fdbb19.r2.dev/SD6602_2038_compressed.jpeg"
  const [aboutPage, setAboutPage] = useState({
      headerUrls: [
        "https://pub-f9e4236cd9a1470d8b18e044f1fdbb19.r2.dev/H33A8911_compressed.jpg"
        ,"https://pub-f9e4236cd9a1470d8b18e044f1fdbb19.r2.dev/H33A8923_compressed.jpg"
        ,"https://pub-f9e4236cd9a1470d8b18e044f1fdbb19.r2.dev/H33A8974_compressed.jpg"
      ],
      intros: {
          en: ["The company now has a daily production capacity of 6,000 square meters, and is expected to reach a daily production capacity of 12,000 square meters by the end of the year. All the products will be exported to Europe, America, South America, Australia and other foreign markets. The company is equipped with one color sorter and three mixing machines to ensure the stability of product color difference.",
          "The company has more than 400 employees and is expected to have more than 600 employees by the end of the year. The first phase now covers an area of 140 mu, and the second phase is planned to cover an area of 148 mu. At present, the first phase has built three high-standard production workshop 60,000 square meters, office building 3,000 square meters, staff dormitory building 3,000 square meters. Among them, the production workshop 60,000 square meters for the installation of photovoltaic power generation project.",
          "The company is equipped with deep processing countertop workshop, equipped with advanced equipment intelligent carving machine, infrared bridge cutting machine, 45 degree chamfering machine, countertop straight edge up and down (side) arc polishing machine, automatic sample block chamfering machine and other high-end equipment, can be completed at one time countertop up and down small rounded corners, fixed thickness, open stop groove, and side polishing processes, independent production and processing of various edge type countertop.",
          "Deep processing with a professional production and processing team, from production to packaging has a complete set of workflow, export countertop technology processing has many years of work experience, to ensure that the process is stable and reliable."],
          zh: ["公司现拥有日产能6000平方米，预计年底将达到日产能12000平方米。产品全部出口到欧洲、美洲、南美、澳大利亚等国外市场。公司配备色选机一台、混色机三台，保证产品色差的稳定性。",
          "公司现有员工400余人，预计年底员工人数将超过600人。目前一期占地140亩，二期规划占地148亩。目前一期已建成三栋高标准生产车间6万平方米，办公楼3000平方米，员工宿舍楼3000平方米。其中，生产车间6万平方米用于安装光伏发电项目。",
          "公司设有深加工台面车间，配备先进设备智能精雕机、红外线桥切机、45度倒角机、台面直边上下（侧面）圆弧抛光机、自动样块倒角机等高精尖设备。端面设备，可一次完成台面上下小圆角、定厚、开止槽、及侧面抛光等工序，独立生产加工各种边型台面。",
          "深加工拥有专业的生产加工团队，从生产到包装有一套完整的工作流程，出口台面技术加工有多年的工作经验，确保工艺稳定可靠。"]
      },
      names: { en: "Saide Quartz Stone Building Materials Co., Ltd.", zh: "Saide Quartz Stone Building Materials Co., Ltd."}
  })

  const [aboutDetails, setAboutDetails] = useState({
    intros: [],
    name: "",
    headerUrls: [],
  })

  const { t, i18n } = useTranslation();

  function loadCurrentIntroWithLocale(docData){
    var aDetails = aboutDetails
    if (docData.headerUrls != null){
      aDetails.headerUrls = docData.headerUrls
    }

    if (i18n.language == 'en'){
      if (docData.intros.en != null){
        aDetails.intros = docData.intros.en
      }
      if (docData.names.en != null){
        aDetails.name = docData.names.en
      }
    }
    else if (i18n.language == 'zh'){
      if (docData.intros.zh != null){
        aDetails.intros = docData.intros.zh
      }
      if (docData.names.zh != null){
        aDetails.name = docData.names.zh
      }
    }

    setAboutDetails(aDetails)
  }
  
  
  useEffect(() => {
      addNewPageLog()
      if (window.location.pathname.endsWith('-zh')){
        i18n.changeLanguage('zh')
      }
      else{
        //en
        i18n.changeLanguage('en')
      }

      getDoc(doc(fs,"info", "aboutPage")).then((docSnap) => {
        if (docSnap.exists){
            const docData = docSnap.data();
            setAboutPage({...docData})

            loadCurrentIntroWithLocale(docData)
        }
    })
  },[])
 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >

<SEOTags
        title={i18n.language == 'en' ? aboutPage.names.en : aboutPage.names.zh}
        description={i18n.language == 'en' ? aboutPage.intros.en.join() : aboutPage.intros.zh.join()}
        name={i18n.language == 'en' ? aboutPage.names.en : aboutPage.names.zh}
        type="article"
        image={aboutPage.headerUrls[0]} />

        <div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>
            <div 
            className="d-flex align-items-center justify-content-center"
            style={{
          width: window.innerWidth,
          height: window.innerHeight / 2,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundImage: `url(${bgImage})`
        }}>
            <h1 className="text-center" style={{color: 'black', fontSize: 45, fontWeight: 'bold'}}>{i18n.language == 'en' ? 'About us' : '关于我们'}</h1>
        </div>



        <Card className="border-0" style={{maxWidth: maxW, margin: isMobile ? '15px' : '60px'}}>
            <Card.Body>
              <Card.Title className="text-center" style={{fontWeight: 'bold', marginTop: '15px', fontSize: 30}}>{i18n.language == 'en' ? aboutPage.names.en : aboutPage.names.zh}</Card.Title>
              <Row md={3} style={{marginTop: '45px'}} hidden={isMobile}>
                  {
                      aboutPage.headerUrls.map((url) => {
                          return <Col>
                          <Card className="border-0">
                            <Card.Img variant="top" src={url}/>
                          </Card>
                          </Col>
                      })
                  }
              </Row>
                {
                      aboutPage.headerUrls.map((url) => {
                          return <Card className="border-0" style={{marginTop: '15px'}} hidden={!isMobile}>
                            <Card.Img variant="top" src={url}/>
                          </Card>
                      })
                  }

              <div style={{marginTop: '100px'}}>
                {
                    aboutPage.intros.en.map((word) => {
                        return <Card.Text hidden={i18n.language == 'zh'} style={{marginTop: '25px', fontSize: 18, fontWeight: 'bold'}}>{word}</Card.Text>
                    })
                }
                {

                    aboutPage.intros.zh.map((word) => {
                      return <Card.Text hidden={i18n.language == 'en'} style={{marginTop: '25px', fontSize: 18, fontWeight: 'bold'}}>{word}</Card.Text>
                    })
                }
              </div>

              
            </Card.Body>
          </Card>

        </div>
        
      </Container>

    </>
  );
}
