import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { addNewPageLog, SEOTags } from "../contexts/API"
import '../contexts/i18n';
import { useTranslation } from "react-i18next";

export default function ProductDetailsPage() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "100%";
  let { productId } = useParams()
  const { t, i18n } = useTranslation();
  const [cateName, setCateName] = useState({en: '', zh: ''})
  const [similarProds, setSimilarProds] = useState([
    {
      photoURL: "",
      photoURLs: [],
      title: "",
      description: "",
      titles: {
          en: "",
          zh: "",
      },
      descriptions: {
          en: [],
          zh: []
      },
      category: "",
      id: "",
      visible: true,
      homepage: true,
      filename: "",
      keywords: [],
    }
  ])
  const [productDetails, setProductDetails] = useState({
    photoURL: "",
    photoURLs: [],
    title: "",
    description: "",
    titles: {
        en: "",
        zh: "",
    },
    descriptions: {
        en: [],
        zh: []
    },
    category: "",
    id: "",
    visible: true,
    homepage: true,
    filename: "",
    keywords: [],
  })
  
  useEffect(() => {
    if (window.location.pathname.includes('-zh')){
      i18n.changeLanguage('zh')
    }
    else{
      //en
      i18n.changeLanguage('en')
    }
      addNewPageLog()
      loadProductDetails()
  },[])


  const loadProductDetails = () => {
      getDoc(doc(fs, "products", productId)).then((snapshot) => {
          if (snapshot.exists){
              //exist
              const productD = snapshot.data()
              setProductDetails({...productD})
              if (productD.category != null){
                const cate_id = productD.category

                getDoc(doc(fs, "categories", cate_id)).then((snapshot_cate) => {
                  if (snapshot_cate.exists){
                    const cateData = snapshot_cate.data();
                    if (cateData.titles != null){
                      setCateName(cateData.titles)
                    }
                  }
                })

                getDocs(query(collection(fs,"products"), where("category","==",cate_id))).then((snapshot_products) => {
                  var prods = []
                  snapshot_products.docs.forEach((prod) => {
                    const prodId = prod.id
                    const prodData = prod.data()
                    prods.push({...prodData})
                  })
                  setSimilarProds(prods)
                })
              }
              
          }
          else{
              window.location.href = "/404"
          }
      })
  }

 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >

    <SEOTags
        title={productDetails.title}
        description={productDetails.description}
        name={"Saide Quartz"}
        type="article"
        image={productDetails.photoURL} />
<div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>

        <div 
            className="align-items-start" style={{marginLeft: '5%', marginRight: '5%', marginTop: '20px'}}>
              <Breadcrumb>
      <Breadcrumb.Item href="/">{i18n.language == 'en' ? 'Home' : "我的主页"}</Breadcrumb.Item>
      <Breadcrumb.Item href={i18n.language == 'en' ? "/brand-center" : "/brand-center-zh"}>
        {i18n.language == 'en' ? 'Brand Center' : '商品中心'}
      </Breadcrumb.Item>
      <Breadcrumb.Item href={i18n.language == 'en' ? "/brand-center" : "/brand-center-zh"}>
        {i18n.language == 'en' ? cateName.en : cateName.zh}
      </Breadcrumb.Item>
      <Breadcrumb.Item active>
        {i18n.language == 'en' ? productDetails.titles.en : productDetails.titles.zh}
      </Breadcrumb.Item>
    </Breadcrumb>
        </div>

        <div style={{margin: "5%"}}>
            <Card className="border-0">
              <Row>
                <Col sm={6}>
                  <Card.Img src={productDetails.photoURL}/>
                </Col>
                <Col sm={6}>
                <Card.Title style={{fontSize: 35, fontWeight: "bold", marginTop: '0px'}}>{i18n.language == 'en' ? productDetails.titles.en : productDetails.titles.zh}</Card.Title>
                <Card.Text style={{marginTop: '15px'}}>{'3200*1600mm(126"*63") Thickness: 20mm, 30mm'}</Card.Text>
                <Card.Text>{i18n.language == 'en' ? 'Keywords: ' : '关键字：'}</Card.Text>
                <Card.Text>{i18n.language == 'en' ? 'Category: ' : '产品类别: '}<Card.Link href={i18n.language == 'en' ? '/brand-center' : '/brand-center-zh'}>{i18n.language == 'en' ? cateName.en : cateName.zh}</Card.Link></Card.Text>
                </Col>
              </Row>
                
                <Card.Text style={{fontSize: 17, fontWeight: 'bold', marginTop: '25px'}}>{i18n.language == 'en' ? "Description" : '产品介绍'}</Card.Text>
                {
                productDetails.descriptions.en.map((txt)=>{
                    return <Card.Text style={{fontSize: 15, fontWeight: 'normal', marginTop: '5px'}} hidden={i18n.language == 'zh'}>{txt}</Card.Text>
                })
            }
            {
                productDetails.descriptions.zh.map((txt)=>{
                    return <Card.Text style={{fontSize: 15, fontWeight: 'normal', marginTop: '5px'}} hidden={i18n.language == 'en'}>{txt}</Card.Text>
                })
            }
                <Card.Text style={{fontSize: 17, fontWeight: 'bold', marginTop: '15px'}}>{i18n.language == 'en' ? "Related Products" : '相似产品'}</Card.Text>
                <Row md={2}>
                {
            similarProds.map((pItm) => {
              return <Col>
              <Card className="border-0" style={{ marginTop: "20px", borderRadius: '0px'}} hidden={isMobile}>
                <Card.Body>
                  <Card.Img variant="top" style={{objectFit: 'cover', height: '250px'}} src={pItm.photoURL}/>
                  <Card.Title style={{marginTop: "10px", marginBottom: "10px", fontSize: 17, fontWeight: "normal"}}>{i18n.language == 'en' ? pItm.titles.en : pItm.titles.zh}</Card.Title>
                  <Card.Link href={i18n.language == 'en' ? "/product/"+pItm.id : "/product-zh/"+pItm.id} style={{marginTop: "5px", marginBottom: "5px", fontSize: 15, fontWeight: "normal", color: 'black'}}>{i18n.language == 'en' ? 'View details' : '查看详情'}</Card.Link>
                </Card.Body>
              </Card>
              </Col>
            })
          }
          </Row>

          {
            similarProds.map((pItm) => {
              return <Card className="border-0" style={{ marginTop: "20px", borderRadius: '0px'}} hidden={!isMobile}>
                <Card.Body>
                  <Card.Img variant="top" style={{objectFit: 'cover', height: '250px'}} src={pItm.photoURL}/>
                  <Card.Title style={{marginTop: "10px", marginBottom: "10px", fontSize: 17, fontWeight: "normal"}}>{i18n.language == 'en' ? pItm.titles.en : pItm.titles.zh}</Card.Title>
                  <Card.Link href={i18n.language == 'en' ? "/product/"+pItm.id : "/product-zh/"+pItm.id} style={{marginTop: "5px", marginBottom: "5px", fontSize: 15, fontWeight: "normal", color: 'black'}}>{i18n.language == 'en' ? 'View details' : '查看详情'}</Card.Link>
                </Card.Body>
              </Card>
            })
          }
            </Card>
            
        </div>

        <Navbar bg="transparent" variant="light" expand="lg" sticky="bottom" style={{height: "80px",}}>
          <Container className="d-flex align-items-center justify-content-center">
            <Button variant="dark" style={{width: "200px", height: "50px", borderRadius: "25px", fontSize: 15, fontWeight: 'bold'}} onClick={()=>{
              if (i18n.language == 'en'){
                window.location.href = "/contact-us/product/" + productId
              }
              else{
                window.location.href = "/contact-us-zh/product/" + productId
              }
              
            }}>{i18n.language == 'en' ? 'Contact us' : '联系我们'}</Button>
          </Container>
        </Navbar>


        </div>
        
      </Container>
      
    </>
  );
}
