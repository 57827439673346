import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Row, Col, InputGroup, Card,
} from "react-bootstrap";
import { 
  isMobile, 
  isAndroid, 
  isIOS } from "react-device-detect";
import { 
  BrowserRouter as Router, 
  Routes, 
  Route } from "react-router-dom";
import { HomeOutlined, UserOutlined, PhoneOutlined, MailOutlined, LinkOutlined } from "@ant-design/icons"
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import {AuthProvider, useAuth} from "./contexts/AuthContext"
import { auth, fs } from './firebase'
import Login from "./components/Login";
import PageNotFound from "./components/404";
import PublicRoute from "./PublicRoute"
import PrivateRoute from "./PrivateRoute"
import HomePage from "./components/HomePage";
import AboutPage from "./components/AboutPage";
import BrandCenterPage from "./components/BrandCenterPage";
import ContactUsPage from "./components/ContactUsPage";
import NewsCenterPage from "./components/NewsCenterPage";
import ProductDetailsPage from "./components/ProductDetails";
import ForgetPasswordPage from "./components/ForgetPassword";

import DashboardPage from "./components/private/DashboardPage";
import './contexts/i18n';
import { useTranslation } from "react-i18next";



function App() {

  const [userId, setUserId] = useState("")
  const [saideInfo, setSaideInfo] = useState({
    address: "Industrial Park, Shenshan Town, Lanling County, Linyi City, Shandong Province",
    email: "jacky@quartzsaide.com",
    phone: "+86-15360618785",
    person: "Jacky H. (Sales Representative)",
    url: "https://quartzsaide.com",
    name: "Saide Quartz Stone Building Materials Co., Ltd.",
  })

  const { t, i18n } = useTranslation();

  const Footer = (name, address, person, phone, email, url, lang) => {
    var onHide = false
    if (window.location.pathname == "/login" || 
        window.location.pathname == "/dashboard") {
      onHide = true
    }
    if (onHide){
      return <></>
    }
    else{
      return <div style={{ backgroundColor: '#333333', padding: '20px'}}>
      <Card.Title className="text-center" style={{fontSize: 25, fontWeight: 'bold', marginBottom: '25px', color: 'white'}}>{name}</Card.Title>
      <div style={{color: 'lightGray'}}>{i18n.language == 'en' ? 'Address: ' : '地址: '}{" "}<Card.Link style={{color: "white"}} href={`https://google.com/search?q=${address}`}>{address}</Card.Link></div><br/>
      <div style={{color: 'lightGray'}}>{i18n.language == 'en' ? 'Contact: ' : '联系人: '} {" "}<Card.Link style={{color: "white"}}>{person}</Card.Link></div><br/>
      <div style={{color: 'lightGray'}}>{i18n.language == 'en' ? 'Tel: ' : '电话: '} {" "}<Card.Link style={{color: "white"}} href={`tel://${phone}`}>{phone}</Card.Link></div><br/>
      <div style={{color: 'lightGray'}}>{i18n.language == 'en' ? 'Email: ' : '电子邮箱: '} {" "}<Card.Link style={{color: "white"}} href={`mailto:${email}`}>{email}</Card.Link></div><br/>
      <div style={{color: 'lightGray'}}>{i18n.language == 'en' ? 'Website: ' : '网址: '} {" "}<Card.Link style={{color: "white"}} href={url}>{url}</Card.Link></div>
      <Card.Text className="text-center" style={{marginTop: '45px', color: "white"}}><b>{name} {i18n.language == 'en' ? ' All Rights Reserved' : ' 保留所有权力'}</b></Card.Text>
      
    </div>
    }
    
  }

  function loadDataBasedonLang(){
    getDoc(doc(fs,"info", "saideInfo")).then((docSnap) => {
      if (docSnap.exists){
          const docData = docSnap.data();
          if (i18n.language == 'en'){
            if (docData.en != null){
              setSaideInfo({...docData.en})
            }
          }
          else if (i18n.language == 'zh'){
            if (docData.zh != null){
              setSaideInfo({...docData.zh})
            }
          }

          
      }
  })
  }

  useEffect(()=>{
    //
    if (auth.currentUser != null){
      setUserId(auth.currentUser.uid)
    }
      getDoc(doc(fs,"info", "saideInfo")).then((docSnap) => {
          if (docSnap.exists){
              const docData = docSnap.data();
              if (i18n.language == 'en'){
                if (docData.en != null){
                  setSaideInfo({...docData.en})
                }
              }
              else if (i18n.language == 'zh'){
                if (docData.zh != null){
                  setSaideInfo({...docData.zh})
                }
              }

              
          }
      })

  }, [])

  return (
    <div style={{}}>
      <Navbar bg="white" variant="light" expand="lg" sticky="top">
            <Container fluid>
              <Navbar.Brand href={i18n.language == 'en' ? `/` : `/zh`} style={{fontSize: 18, fontWeight: 'bold'}}>
              {i18n.language == 'en' ? 'Saide Quartz' : '赛德石英石'}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0"/>
              <Navbar.Collapse id="basic-navbar-nav" className="border-0" style={{}}>
          <Nav className="me-auto">
            <Nav.Link href={i18n.language == 'en' ? `/about-us` : `/about-us-zh`} style={{fontSize: '15px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'About us' : '关于我们'}</Nav.Link>
            <Nav.Link href={i18n.language == 'en' ? `/brand-center` : `/brand-center-zh`} style={{fontSize: '15px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Brand Center' : '商品中心'}</Nav.Link>
            <Nav.Link hidden={true} href={i18n.language == 'en' ? `/news-center` : `/news-center-zh`} style={{fontSize: '15px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'News Center' : '新闻中心'}</Nav.Link>
            <Nav.Link href={i18n.language == 'en' ? `/contact-us` : `/contact-us-zh`} style={{fontSize: '15px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Contact us' : '联系我们'}</Nav.Link>
            <Nav.Link href="/login" hidden={userId != ""} style={{fontSize: '15px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Login' : '登录'}</Nav.Link>
            <NavDropdown 
            title={i18n.language == 'en' ? 'English' : '简体中文'}
            style={{fontSize: '15px', fontWeight: 'bold'}}
            id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>{
                i18n.changeLanguage('en')
                loadDataBasedonLang()
              }}>
                English
              </NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{
                i18n.changeLanguage('zh')
                loadDataBasedonLang()
                
              }}>
                简体中文
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </Navbar.Collapse>
        </Container>
        </Navbar>
        <AuthProvider>
          <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/zh" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/brand-center" element={<BrandCenterPage />} />
            <Route path="/news-center" element={<NewsCenterPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/home-zh" element={<HomePage />} />
            <Route path="/about-us-zh" element={<AboutPage />} />
            <Route path="/brand-center-zh" element={<BrandCenterPage />} />
            <Route path="/news-center-zh" element={<NewsCenterPage />} />
            <Route path="/contact-us-zh" element={<ContactUsPage />} />
            <Route path="/contact-us/product/:productId/" element={<ContactUsPage />} />
            <Route path="/contact-us-zh/product/:productId/" element={<ContactUsPage />} />
            <Route path="/contact-us/collection/:collectionId/:lang" element={<ContactUsPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPasswordPage />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/product/:productId" element={<ProductDetailsPage />} />
            <Route path="/product-zh/:productId" element={<ProductDetailsPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/brand-center" element={<BrandCenterPage />} />
            <Route path="/news-center" element={<NewsCenterPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          </Routes>
        </AuthProvider>

        {
          Footer(
            saideInfo.name,
            saideInfo.address,
            saideInfo.person, 
            saideInfo.phone, 
            saideInfo.email, 
            saideInfo.url, 
            "en")
        }
        
    </div>
  );
}

export default App;
