import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { addNewPageLog, SEOTags } from "../contexts/API"
import '../contexts/i18n';
import { useTranslation } from "react-i18next";

export default function ContactUsPage() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "80%";
  const bgImage = "https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b"
  
  const { productId, collectionId } = useParams()
  const { t, i18n } = useTranslation();

  const submitForm = (e) => {
      e.preventDefault()
      const fn = e.target[0].value
      const ln = e.target[1].value
      const email = e.target[2].value
      const phone = e.target[3].value
      const message = e.target[4].value
      
      //create form ticket
      addDoc(collection(fs,"forms"), {
          firstName: fn,
          lastName: ln,
          email: email,
          phone: phone,
          message: message,
          timestamp: Timestamp.fromDate(new Date())
      }).then((result) => {
          const submissionId = result.id
          setSuccess(i18n.language == 'en' ? "We have received your request, we will reach out to you later\nSubmission ID: " + submissionId : '您的信息已经提交成功，我们稍后会联系您\nSubmission ID: ' + submissionId)
          e.target[0].value = ""
          e.target[1].value = ""
          e.target[2].value = ""
          e.target[3].value = ""
          e.target[4].value = ""
          
          addDoc(collection(fs,"mail"), {
              to: ["quartz.saide@gmail.com","peterhe080@gmail.com"],
              message: {
                  subject: "[QuartzSaide] New message from " + fn + " " + ln,
                  text: "Name: " + fn + " " + ln + "\n\n" + "Email: " + email + "\n\n" + "Message: " + message + "\n\n" + "Form source: Homepage",
              }
          })
      })
  }
  
  useEffect(() => {
      addNewPageLog()
      if (window.location.pathname.includes('-zh')){
        i18n.changeLanguage('zh')
      }
      else{
        //en
        i18n.changeLanguage('en')
      }
  },[])
 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >

<SEOTags
        title={"Saide Quartz | Contact us"}
        description={"Send us a message today"}
        name={"Saide Quartz"}
        type="article"
        image={bgImage} />

        <div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>
            {/* <div 
            className="d-flex align-items-center justify-content-center"
            style={{
          width: window.innerWidth,
          height: window.innerHeight / 2,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b)"
        }}>
            <h1 className="text-center" style={{color: 'black', fontSize: isMobile ? 35 : 45, fontWeight: 'bold'}}>Contact us</h1>
        </div> */}

        <div className="d-flex align-items-center justify-content-center">

        <Card className="" style={{ marginLeft: '20px', marginRight: '20px', marginTop: '45px', marginBottom: '45px'}}>
            <Card.Body>
            <h1 className="text-center" style={{fontSize: isMobile ? 30 : 40, fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Send us message' : '联系我们'}</h1>
            <h5 className="text-center" style={{marginBottom: '15px'}}>{i18n.language == 'en' ? 'Please let us know how we can help you' : '我们需要如何帮您'}</h5>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={(e)=>{submitForm(e)}}>
                  <Row md={2} style={{marginTop: '25px'}}>
                      <Col>
                      <Form.Group>
                    <FloatingLabel label={i18n.language == 'en' ? 'First Name *' : '您的名字 First Name *'}>
                        <Form.Control type="name" required/>  
                    </FloatingLabel>
                  </Form.Group>
                      </Col>

                      <Col>
                      <Form.Group>
                    <FloatingLabel label={i18n.language == 'en' ? 'Last Name *' : '您的贵姓 Last Name *'}>
                        <Form.Control type="name" required/>  
                    </FloatingLabel>
                  </Form.Group>
                      </Col>

                  </Row>
                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label={i18n.language == 'en' ? 'Email Address *' : '您的电子邮箱 *'}>
                        <Form.Control type="email" required/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label={i18n.language == 'en' ? 'Phone Number' : '您的手机号'}>
                        <Form.Control type="phone"/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label={i18n.language == 'en' ? 'Message' : '您想要说的话'}>
                        <Form.Control as="textarea" rows={5}/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                      <Button className="w-100" variant="dark" style={{height: '45px', fontWeight: 'bold'}} type="submit">{i18n.language == 'en' ? 'Submit' : '提交'}</Button>
                  </Form.Group>
              </Form>

              
            </Card.Body>
          </Card>
          

        </div>

        
        </div>

        
      </Container>
     
    </>
  );
}
