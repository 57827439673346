import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail, sendEmailVerification, updateEmail, updatePassword } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
  
    function login(email, password) {
      return signInWithEmailAndPassword(auth,email,password)
    }
  
    function logout() {
      return signOut(auth);
    }
  
    function resetPassword(email) {
      return sendPasswordResetEmail(auth, email);
    }
  
    function updateUserEmail(email) {
      const user = auth.currentUser;
      return updateEmail(user,email);
    }
  
    function updateUserPassword(password) {
      const user = auth.currentUser;
      return updatePassword(user,password);
    }

    function sendUserEmailVerification() {
        const user = auth.currentUser;
        return sendEmailVerification(user);
    }
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setCurrentUser(user);
        setLoading(false);
      });
      return unsubscribe;
    }, []);
  
    const value = {
      currentUser,
      login,
      logout,
      resetPassword,
      updateUserEmail,
      updateUserPassword,
      sendUserEmailVerification
    };
  
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    );
  }
  