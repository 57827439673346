import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col, Carousel, Modal, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { addNewPageLog } from "../contexts/API"
import '../contexts/i18n';
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "100%";
  const [slideCount, setSlideCount] = useState(0)
  const [feedbackCancel, setFeedbackCancel] = useState(false)
  const bgImage = "https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b"
  
  let { lang } = useParams()

  const { t, i18n } = useTranslation();
  
  const [showForm, setShowForm] = useState(false);
  const [imageUrls, setImageUrls] = useState([
    require("./SD6602_2038_compressed.jpeg"),
      require("./SD6632_compressed.jpeg"),
      require("./H33A8974_compressed.jpg"),
  ])

  const submitForm = (e) => {
      e.preventDefault()
      const fn = e.target[0].value
      const ln = e.target[1].value
      const email = e.target[2].value
      const phone = e.target[3].value
      const message = e.target[4].value

      addDoc(collection(fs,"forms"), {
        firstName: fn,
        lastName: ln,
        email: email,
        phone: phone,
        message: message,
        timestamp: Timestamp.fromDate(new Date())
    }).then((result) => {
          e.target[0].value = ""
          e.target[1].value = ""
          e.target[2].value = ""
          e.target[3].value = ""
          e.target[4].value = ""
          addDoc(collection(fs,"mail"), {
              to: ["quartz.saide@gmail.com","peterhe080@gmail.com"],
              message: {
                  subject: "New message from " + fn + " " + ln,
                  text: "Name: " + fn + " " + ln + "\n\n" + "Email: " + email + "\n\n" + "Message: " + message + "\n\n" + "Form source: Homepage",
              }
          }).then((resultA) => {
            setSlideCount(0)
            setShowForm(false);
            setFeedbackCancel(true);
          })
    })
  }

  const contactForm = () => {
      return <div>
          <Modal 
          show={showForm}
          onHide={() => {
            setSlideCount(0)
            setShowForm(false);
            setFeedbackCancel(true);
          }}>
              <Modal.Header closeButton>
                  <Card.Title>{i18n.language == 'en' ? 'Contact us' : '联系我们'}</Card.Title>
              </Modal.Header>
              <Modal.Body>

              <Form onSubmit={(e)=>{submitForm(e)}}>
                  <Row md={2} style={{marginTop: '25px'}}>
                      <Col>
                      <Form.Group>
                    <FloatingLabel label={i18n.language == 'en' ? 'First Name *' : '您的名字 *'}>
                        <Form.Control type="name" required/>  
                    </FloatingLabel>
                  </Form.Group>
                      </Col>

                      <Col>
                      <Form.Group>
                    <FloatingLabel label={i18n.language == 'en' ? 'Last Name *' : '您的贵姓 *'}>
                        <Form.Control type="name" required/>  
                    </FloatingLabel>
                  </Form.Group>
                      </Col>

                  </Row>
                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label={i18n.language == 'en' ? 'Email Address *' : '您的电子邮箱 *'}>
                        <Form.Control type="email" required/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label={i18n.language == 'en' ? 'Phone Number' : '您的手机号'}>
                        <Form.Control type="phone"/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                    <FloatingLabel label={i18n.language == 'en' ? 'Message' : '留言'}>
                        <Form.Control as="textarea" rows={5}/>  
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group style={{marginTop: '25px'}}>
                      <Button className="w-100" variant="dark" style={{height: '45px', fontWeight: 'bold'}} type="submit">{i18n.language == 'en' ? 'Submit' : '提交'}</Button>
                  </Form.Group>
              </Form>

              </Modal.Body>
          </Modal>
      </div>
  }

  
  

  
  useEffect(() => {
    if (window.location.pathname.endsWith('zh')){
      i18n.changeLanguage('zh')
    }
    else{
      //en
      i18n.changeLanguage('en')
    }
    addNewPageLog()
    getDoc(doc(fs,"info", "homePage")).then((docSnap) => {
        if (docSnap.exists){
            const docData = docSnap.data();
            var urls = imageUrls
            var header = ""
            var end = ""
            if (docData.header != null){
                header = docData.header
            }
            if (docData.end != null){
                end = docData.end
            }
            if (docData.urls != null){
                docData.urls.forEach(element => {
                    var newUrl = header + element + end
                    urls.push(newUrl)
                });
                setImageUrls(urls)
            }
        }
    })
      

  },[])
 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >
        <div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>

            {
                contactForm()
            }
           <Carousel 
           controls={true}
           indicators={false}
           style={{
               width: window.outerWidth, 
               height: window.outerHeight,
               opacity: 0.8}}
               onSlide={(e) => {
                  //slide to number e
                  if (slideCount < 2){
                    setSlideCount(prevState => {
                      return prevState + 1
                    })
                  }
                  else{
                    setShowForm(true)
                    setSlideCount(prevState => {
                      return 0
                    })
                  }

                  
                  
               }}
               >
               {
                   imageUrls.map((url) => {
                       return <Carousel.Item interval={3000}>
                         
              <img width={window.innerWidth} height={window.innerHeight + 80 + 80} style={{objectFit: 'cover', transition: 'ease-out'}} src={url}/>
              
        </Carousel.Item>
                   })
               }
      
      </Carousel>

      

      <Navbar bg="transparent" variant="light" expand="lg" sticky="bottom" style={{height: "80px",}}>
          <Container className="d-flex align-items-center justify-content-center">
            <Button variant="dark" style={{width: "200px", height: "50px", borderRadius: "25px", fontSize: 15, fontWeight: 'bold'}} onClick={()=>{
              setShowForm(true)
            }}>{i18n.language == 'en' ? 'Contact us' : '联系我们'}</Button>
          </Container>
        </Navbar>
            
        </div>

        
        
      </Container>
      
    </>
  );
}
