import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Navbar, ListGroup, Accordion, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuth } from "../contexts/AuthContext";
import { fs, auth } from "../firebase";
import { Helmet } from "react-helmet";
import { collection, addDoc, getDoc, getDocs, doc, Timestamp, where, orderBy, query } from "firebase/firestore";
import { addNewPageLog, SEOTags } from "../contexts/API"
import '../contexts/i18n';
import { useTranslation } from "react-i18next";

export default function NewsCenterPage() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "100%";
  const bgImage = "https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b"
  
  const { t, i18n } = useTranslation();

  useEffect(() => {
      addNewPageLog()

      if (window.location.pathname.endsWith('-zh')){
        i18n.changeLanguage('zh')
      }
      else{
        //en
        i18n.changeLanguage('en')
      }

  },[])
 

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "#ffffff",
        }}
      >

<SEOTags
        title={"Saide Quartz | News Center"}
        description={"View our latest news online"}
        name={"Saide Quartz"}
        type="article"
        image={bgImage} />

<div style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}>
            <div 
            className="d-flex align-items-center justify-content-center"
            style={{
          width: window.innerWidth,
          height: window.innerHeight / 2,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/quartz-saide.appspot.com/o/saide%2F1000%20.JPG?alt=media&token=642e4305-7f08-483f-a0fe-27221da7dd3b)"
        }}>
            <h1 className="text-center" style={{color: 'black', fontSize: 45, fontWeight: 'bold'}}>{i18n.language == 'en' ? 'News Center' : '新闻中心'}</h1>
        </div>

        <div style={{margin: "45px"}}>

          <Row>
                <Col sm={2} style={{marginTop: '10px'}}>
                <ListGroup className="border-0" style={{borderRadius: '0px'}}>
                                <ListGroup.Item className='border-0' style={{borderRadius: '0px'}} action onClick={()=>{
                                  
                                }}>
                                    <div className={'fw-bold'}>{i18n.language == 'en' ? '• All News' : '• 全部新闻'}</div>
                                </ListGroup.Item>
                                <ListGroup.Item className='border-0' style={{borderRadius: '0px'}} action onClick={()=>{
                                  
                                }}>
                                    <div className={'fw-normal'}>{i18n.language == 'en' ? '• Recent News' : '• 近期新闻'}</div>
                                </ListGroup.Item>
                                <ListGroup.Item className='border-0' style={{borderRadius: '0px'}} action onClick={()=>{
                                  
                                }}>
                                    <div className={'fw-normal'}>{i18n.language == 'en' ? '• Past News' : '• 过往新闻'}</div>
                                </ListGroup.Item>
                                </ListGroup>
                  </Col>
                  <Col sm={10} style={{marginTop: '10px'}}>
                
                  </Col>
        </Row>
        </div>


        </div>
        
      </Container>
      
    </>
  );
}
